import React from 'react'
import classNames from 'classnames'
import { Form, Label } from 'semantic-ui-react'
import { Field, ErrorMessage } from 'formik'

const TextInput = ({ ...props }) => <Field component={FSTextInputBlock} {...props} />

const FSTextInputBlock = ({ form: { touched, errors, isSubmitting }, field, ...props }) => {
  const { name } = field
  const {
    label,
    prefix,
    prefixContent,
    suffix,
    suffixContent,
    placeholder,
    required,
    disabled
  } = props
  return (
    <>
      <Form.Field
        required={required}
        error={errors[name] && touched[name]}
        disabled={disabled || isSubmitting}
      >
        <label>{label}</label>
        <div
          className={classNames('ui fluid input', {
            'right labeled': suffix,
            'left labeled': prefix
          })}
        >
          {prefix && <Label>{prefixContent}</Label>}
          <input name={name} placeholder={placeholder} {...field} />
          {suffix && <Label>{suffixContent}</Label>}
        </div>
        <ErrorMessage name={name} render={renderError} />
      </Form.Field>
    </>
  )
}

const renderError = msg => <div className="ui pointing above prompt label">{msg}</div>

export default TextInput
