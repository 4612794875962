import axios from 'axios'

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || 'https://backend.contegospa.com/'
})

export const submitContactForm = payload => client.post('/bullarum-api/contact', payload)
export const submitLeadForm = payload => client.post('/bullarum-api/lead', payload)
export const submitLandingPageForm = payload => client.post('/bullarum-api/landing-page', payload)
export const subscribeNewsletter = payload => client.post('/bullarum-api/newsletter', payload)
